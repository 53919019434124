































import Vue from 'vue'
import Component from 'vue-class-component';
import Breadcrumb from '@/components/common/Breadcrumb.vue';
import Widget from '@/components/common/Widget.vue';
import DataTable from '@/components/table/DataTable.vue';

declare const $:any;

@Component({components: {Breadcrumb, Widget, DataTable}})
export default class AccountSecurity extends Vue
{

}
